// main scss file
// not much css right now.
// if css increases more than 100 lines, it will be divided in multiple files and importerd here

@import url("https://fonts.googleapis.com/css?family=Roboto&display=swap");

html,
body {
  font-family: "Roboto", sans-serif;
}

// override classes

.form-control,
.btn {
  &:focus {
    box-shadow: none;
  }
}

.btn:disabled {
  cursor: not-allowed;
}

.btn.btn-sm {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}

// component classes

.loading-screen {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(#fff, 0.8);
  z-index: 9999;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(#fff, 0.8);
  z-index: 9998;
  justify-content: center;
  padding-top: 80px;
}

.fab-wrapper {
  position: fixed;
  bottom: 1.5rem;
  right: 1.5rem;
  z-index: 99;
  .fab-btn {
    padding: 0;
    height: 48px;
    width: 48px;
    font-size: 2rem;
    font-weight: bold;
    border-radius: 50%;
  }
  @media (min-width: 768px) {
    bottom: 2rem;
    right: 2rem;
    .fab-btn {
      height: 56px;
      width: 56px;
    }
  }
}

.login-box {
  width: calc(100% - 1.5rem);
  max-width: 400px;
  margin: 0 auto;
}

// specific classes

.details {
  white-space: pre-wrap;
  word-break: break-word;
}

.w-600 {
  width: 600px;
  max-width: 90%;
}

.w-300 {
  width: 300px;
  max-width: 90%;
}

.weather-img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-size: cover;
  background-position: center;
}

.weather-result {
  position: absolute;
  color: #fff;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.7) 5%, transparent);
  bottom: 0;
  left: 0;
  right: 0;
  padding: 80px 15px 15px;
}

.button-group {
  .btn {
    &:not(:last-child) {
      margin-right: 0.5rem;
    }
  }
}
