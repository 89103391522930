:root {
  --navbar-height: 50px;
}

.navbar {
  height: var(--navbar-height);
  &.navbar-light {
    .nav-link {
      color: #000;
    }
  }
  &.navbar-dark {
    .nav-link {
      color: #fff;
    }
  }
  @media (max-width: 991px) {
    .navbar-collapse {
      position: fixed;
      width: 75%;
      max-width: 350px;
      top: var(--navbar-height);
      left: 0;
      bottom: 0;
      z-index: 101;
      padding: 0.5rem 1rem;
      transition: 0.25s all;
      transform: translateX(-100%);
      &.show {
        transform: translateX(0);
        background: var(--bs-light);
        box-shadow: 5px 0px 10px -5px rgba(#333, 0.4);
      }
      .nav-link,
      .nav-link:hover,
      .nav-link.active {
        color: #464646;
      }
    }

    .navbar-toggler {
      border: 0;
      height: 32px;
      width: 32px;
      padding: 0;
      right: 1rem;
      position: absolute;
      z-index: 98;
      outline: none;
      .toggle-icon {
        display: block;
        height: 2px;
        width: 80%;
        margin: 0 auto;
        background-color: currentColor;
        font-size: 0;
        &:before,
        &:after {
          content: "";
          display: block;
          height: 2px;
          width: 80%;
          position: absolute;
          background-color: currentColor;
          transition: 0.25s all;
        }
        &:before {
          top: 6px;
        }
        &:after {
          bottom: 6px;
        }
      }
      &:not(.collapsed) {
        .toggle-icon {
          &:before,
          &:after {
            width: 100%;
          }
        }
      }
      &:focus {
        box-shadow: none;
      }
    }
  }

  .app-logo {
    height: 30px;
    width: 30px;
    border-radius: 0.25rem;
    margin-right: 0.5rem;
  }
}
